import { Image, notDesktop, styled } from '@obvio/app'
import { RichText } from '@obvio/template'
import { Grid } from '@obvio/ui'

import type { ImageAsset } from '@obvio/app'
import type { ReactElement } from 'react'

const Wrap = styled(Grid)`
  @media ${notDesktop} {
    grid-template-columns: 1fr;
  }
`

const ImageWrap = styled.div<{ $ratio: number }>`
  width: 100%;
  aspect-ratio: ${(_, { $ratio }) => $ratio};
`

type RichTextImageProps = {
  description: string
  imagePosition: boolean
  image: ImageAsset
}
export function RichTextImage({
  description,
  imagePosition,
  image,
}: RichTextImageProps): ReactElement {
  return (
    <Wrap
      templateColumns={imagePosition ? '1.25fr 1fr' : '1fr 1.25fr'}
      gap="10rem"
    >
      <div style={{ order: imagePosition ? 1 : 0 }}>
        <RichText value={description} />
      </div>
      <ImageWrap $ratio={image.ratio}>
        <Image img={image} sizes="700px" />
      </ImageWrap>
    </Wrap>
  )
}
